@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&display=swap");

html,
body {
  min-width: 100vw !important;
  min-height: fit-content;
  display: flex;
  justify-content: center;
  height: 100%;

}

#root {
  min-width: 40%;
  max-width: 450px;
  min-height: 100%;
  max-height: 750px;
  background: #ffffff;
}

@media only screen and (max-width: 450px) {
  #root {
    width: 100% !important;
  }
}
